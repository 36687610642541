<!--设备列表-->
<template>
	<div class="page">
		<Tables :showSeq="false" ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true"
			:showSearch="true" :showAdd="$hasAccess('group.bill/index')" addName="支付物业金额" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
<!--				<el-select v-model="searchFormData.group_id" placeholder="按所属小区查询" clearable class="from-item-width">-->
<!--					<el-option v-for="group in groupList" :key="group.group_id" :label="group.group_name" :value="group.group_id"></el-option>-->
<!--				</el-select>-->
				<el-select
						v-model="searchFormData.group_id"

						class="from-item-width"
						filterable
						reserve-keyword
						clearable
						placeholder="按所属小区查询"
						:remote-method="remoteGroup"
						:loading="groupLoading">
					<el-option
							v-for="item in groupList"
							:key="item.group_id"
							:label="item.group_name"
							:value="item.group_id">
					</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_id" title="小区ID" align="center" />
			<vxe-table-column slot="table-item" field="group_name" title="小区名称" align="center" min-width="200px" />
			<vxe-table-column slot="table-item" field="record_money" title="入账金额" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="type" title="分成模式" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{row.type === 1 ? '电费' : '分成'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="打款状态" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span :class="payStatus[row.status].class">{{payStatus[row.status].txt || '未知'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="payment_time" title="打款时间" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{row.payment_time ? row.payment_time : '--'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="payment_time" title="结算时间" align="center" min-width="60px">
				<template v-slot="{ row }">
					<span>{{row.set_time ? row.set_time : '--'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="create_user_id" title="录入人ID" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="create_user_name" title="录入人姓名" align="center" min-width="60px" />
			<vxe-table-column slot="table-item" field="create_time" title="录入时间" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="remark" title="备注" align="center" />
			<vxe-table-column slot="table-item" title="操作" align="center" width="100">
				<template v-slot="{ row }">
					<el-popconfirm title="确认进行结算标记？" @confirm="onPayment(row)">
						<el-button :disabled="row.status !== 0" slot="reference" size="small">结算</el-button>
					</el-popconfirm>
					<el-button size="small" plain @click="edit(row)" v-if="$hasAccess('group.wuye/edit')">编辑</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 新增 -->
		<el-dialog title="支付物业金额" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-form :model="addDialogFormData" :rules="rules" ref="addForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="小区名称" prop="group_id">
<!--					<el-select class="select" v-model="addDialogFormData.group_id" placeholder="请选择小区" clearable>-->
<!--						<el-option v-for="group in groupList" :key="group.group_id" :label="group.group_name" :value="group.group_id"></el-option>-->
<!--					</el-select>-->
					<el-select
							v-model="addDialogFormData.group_id"
							class="from-item-width"
							filterable
							reserve-keyword
							clearable
							placeholder="请选择小区"
							:remote-method="remoteGroup"
							:loading="groupLoading">
						<el-option
								v-for="item in groupList"
								:key="item.group_id"
								:label="item.group_name"
								:value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分成模式" prop="type">
					<el-select class="select" v-model="addDialogFormData.type" placeholder="请选择分成模式" clearable>
						<el-option :key="1" label="电费" :value="1"></el-option>
						<el-option :key="2" label="分成" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入账金额" prop="record_money">
					<el-input v-model="addDialogFormData.record_money" placeholder="请输入入账金额" clearable></el-input>
				</el-form-item>
				<el-form-item label="结算时间" prop="select_time">
					<el-date-picker class="from-item-width" style="width: 100%"
									unlink-panels
									v-model="addDialogFormData.select_time"
									type="datetime"   placeholder="选择时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="addDialogFormData.remark" placeholder="备注" clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd()" v-if="$hasAccess('group.bill/index')">保 存 编 辑 </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
		<!-- 编辑 -->
		<el-dialog title="编辑" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-form :model="editDialogFormData" :rules="rules" ref="addForm" label-width="auto" class="add-dialog-form">
				<el-form-item label="小区名称" prop="group_id">
					<el-select
							v-model="editDialogFormData.group_id"
							class="from-item-width"
							filterable
							reserve-keyword
							clearable
							placeholder="请选择小区"
							:remote-method="remoteGroup"
							:loading="groupLoading">
						<el-option
								v-for="item in groupList"
								:key="item.group_id"
								:label="item.group_name"
								:value="item.group_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分成模式" prop="type">
					<el-select class="select" v-model="editDialogFormData.type" placeholder="请选择分成模式" clearable>
						<el-option :key="1" label="电费" :value="1"></el-option>
						<el-option :key="2" label="分成" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="入账金额" prop="record_money">
					<el-input v-model="editDialogFormData.record_money" placeholder="请输入入账金额" clearable></el-input>
				</el-form-item>
				<el-form-item label="结算时间" prop="select_time">
					<el-date-picker class="from-item-width" style="width: 100%"
									unlink-panels
									v-model="editDialogFormData.payment_time"
									type="datetime"   placeholder="选择时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="editDialogFormData.remark" placeholder="备注" clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveedit()" v-if="$hasAccess('group.wuye/edit')">保 存 编 辑 </el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消 </el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import Tables from "@/components/tables";
	export default {
		name: "property-bill-management",
		components: {
			Tables,
		},
		data() {
			return {
				// table信息
				tableName: "设备清单",
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				billList: [],
				selecttime: {},
				editselecttime: {},
				groupList: [],
				groupLoading: false,
				payStatus: {
					0: {
						txt: '未结算',
						class: 'sd_error',
					},
					1: {
						txt: '已结算',
						class: 'sd_spare'
					}
				},
				addDialogShow: false,
				addDialogFormData: {},
				editDialogShow:false,
				editDialogFormData: {},
				rules: {
					group_id: [{
						required: true,
						message: '请选择小区',
						trigger: ['blur', 'change']
					}],
					type: [{
						required: true,
						message: '请选择分成模式',
						trigger: ['blur', 'change']
					}],
					record_money: [{
						required: true,
						message: '请输入入账金额',
						trigger: ['blur', 'change']
					}],
				}
			};
		},
		mounted() {
			this.init();
		},
		activated() {
			this.init();
			this.$refs.xTable.refreshTable();
		},
		methods: {
			// 初始化请求
			init() {
				this.getGroupList();
			},
			// 获取小区信息
			async getGroupList() {
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 1000,
				};
				const res = await this.$api.Device.GetGroupList(params, {});
				this.groupList = res.data;
			},
			// 获取列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize,
					group_id: this.searchFormData.group_id
				};
				this.isLoading = true;
				const searchData = this.searchFormData;
				const res = await this.$api.Bill.GetPropertyBillList(params);
				this.tableData = res.data;
				this.totalPage = res.total;
				this.isLoading = false;
			},
			// 提交记录
			async addPropertyBill() {
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(this.addDialogFormData),
				}
				await this.$api.Bill.AddPropertyBill(params);
				this.$notify({
					title: "成功",
					message: "添加成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();
			},
			// 小区
			async remoteGroup (query) {
				if (query !== '') {
					this.groupLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const res = await this.$api.Device.GetGroupList(params, {
						group_name: query
					})
					this.groupList = res.data
					this.groupLoading = false
				} else {
					this.groupList = []
				}
			},
			async onPayment(row) {
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				await this.$api.Bill.PropertyPay(params);
				this.$notify({
					title: "成功",
					message: "结算成功",
					type: "success",
				});
				this.getList();
			},
			// 重置查询
			clearSearch() {
				this.searchFormData = {};
			},
			// 新增设备
			addOne() {
				this.addDialogShow = true;
				this.selecttime='';
			},
			saveAdd() {
				if (this.addDialogFormData.select_time) {
					this.selecttime= parseInt(this.addDialogFormData.select_time.getTime() / 1000);
					this.addDialogFormData.payment_time=this.selecttime;
				}else {
					this.addDialogFormData.payment_time="";
				}
				if (!this.addDialogFormData.group_id) {
					this.$refs.addForm.validateField('group_id');
					return;
				}
				if (!this.addDialogFormData.record_money) {
					this.$refs.addForm.validateField('record_money');
					return;
				}
				this.addPropertyBill();
			},
			async edit (row) {
				this.editDialogFormData = {};
				this.editDialogShow = true;
				this.editselecttime={};
				const params = {
					token: this.$store.state.user.token,
					id: row.id
				}
				const res = await this.$api.Bill.showbyid(params);
				if (res) {
					const {
						id,
						group_id,
						type,
						record_money,
						payment_time,
						remark
					} = res;
					this.editDialogFormData = {
						id,
						group_id,
						type,
						record_money,
						payment_time,
						remark
					};
					this.editDialogFormOriData = {
						id,
						group_id,
						type,
						record_money,
						payment_time,
						remark
					};
				}
				this.editDialogShow = true;
			},
			saveedit() {
				if (!this.editDialogFormData.group_id) {
					this.$refs.editForm.validateField('group_id');
					return;
				}
				if (!this.editDialogFormData.type) {
					this.$refs.editForm.validateField('type');
					return;
				}
				if (!this.editDialogFormData.record_money) {
					this.$refs.editForm.validateField('record_money');
					return;
				}
				this.editGroupBill();
			},
			// 提交编辑
			async editGroupBill() {
				if (this.editDialogFormData.payment_time.length==undefined) {
					this.editselecttime= parseInt(this.editDialogFormData.payment_time.getTime() / 1000);
					this.editDialogFormData.edit_time=this.editselecttime;
				}else {
					if (this.editDialogFormData.payment_time){
						this.editDialogFormData.edit_time=this.editDialogFormData.payment_time;
					}else {
						this.editDialogFormData.edit_time="";
					}
				}
				const form = {
					id: this.editDialogFormData.id,
					group_id: this.editDialogFormData.group_id,
					type: this.editDialogFormData.type,
					record_money: this.editDialogFormData.record_money,
					payment_time: this.editDialogFormData.edit_time,
					remark: this.editDialogFormData.remark
				}
				const params = {
					token: this.$store.state.user.token,
					form: JSON.stringify(form),
				}
				await this.$api.Bill.editWuye(params);
				this.$notify({
					title: "成功",
					message: "编辑成功",
					type: "success",
				});
				this.closeDialog();
				this.getList();
			},
			closeDialog() {
				this.addDialogShow = false;
				this.editDialogShow = false;
			}
		},
	};
</script>
<style lang="less" scoped>
	.from-item-width {
		width: 180px;
	}

	.sd_spare {
		color: #008e4d;
	}

	.sd_spare2 {
		color: #0055ff;
	}

	.sd_error {
		color: #f56c6c;
	}

	.sd_total {
		color: #b28e2f;
	}

	.add-dialog-form {
		.select {
			width: 100%;
		}
	}
</style>
<style>
	.el-popover {
		text-align: right;
	}
</style>
